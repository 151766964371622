<template>
  <div class="content fs-6 d-flex flex-column flex-column-fluid">
    <div class="toolbar">
      <div class="container-fluid d-flex flex-stack flex-wrap flex-sm-nowrap">
        <div
          class="d-flex flex-column align-items-start justify-content-center flex-wrap me-2"
        >
          <h1 class="text-dark fw-bolder my-1 fs-2">Permis de construire</h1>
        </div>
        <div class="d-flex align-items-center flex-nowrap text-nowrap py-1">
          <span v-if="permits" class="badge badge-primary">{{
            permits.count
          }}</span>
        </div>
      </div>
    </div>
    <div class="container-fluid d-flex justify-content-end mb-5">
      <button
        v-if="permitsFilter.nb_lls__gt === false"
        type="button"
        class="btn me-3 btn-light-primary"
        @click.prevent="filterLLS0()"
      >
        Nombre LLS > 0
      </button>
      <button
        v-else
        type="button"
        class="btn me-3 btn-primary"
        @click.prevent="deleteFilterLLS0()"
      >
        Nombre LLS > 0
      </button>
      <button
        v-if="permitsFilter.city__decote_prix_ancien__gt === ''"
        type="button"
        class="btn btn-light-primary me-3"
        @click.prevent="filterDecotePrixAncienGt()"
      >
        Décote prix LLS > 20 %
      </button>
      <button
        v-else
        type="button"
        class="btn me-3 btn-primary"
        @click.prevent="deleteFilterDecotePrixAncienGt()"
      >
        Décote prix LLS > 20 %
      </button>
      <button
        v-if="ownerIsActive == false"
        type="button"
        class="btn btn-light-primary me-3"
        @click.prevent="filterOwner()"
      >
        Mes permis attribués
      </button>
      <button
        v-else
        type="button"
        class="btn me-3 btn-primary"
        @click.prevent="deleteFilterOwner()"
      >
        Mes permis attribués
      </button>
      <button
        v-if="favouritesIsActive == false"
        type="button"
        class="btn btn-light-primary me-3"
        @click.prevent="filterFavourites()"
      >
        Permis favoris
      </button>
      <button
        v-else
        type="button"
        class="btn me-3 btn-primary"
        @click.prevent="deleteFilterFavourites()"
      >
        Permis favoris
      </button>
      <button
        v-if="outOfScopeIsActive == false"
        type="button"
        class="btn btn-light-primary me-3"
        @click.prevent="filterOutOfScope()"
      >
        Permis hors scope
      </button>
      <button
        v-else
        type="button"
        class="btn me-3 btn-primary"
        @click.prevent="deleteFilterOutOfScope()"
      >
        Permis hors scope
      </button>
      <button
        type="button"
        class="btn btn-light-danger"
        @click.prevent="deleteAllFilters()"
      >
        Supprimer les filtres
      </button>
    </div>

    <div class="post fs-6 d-flex flex-column-fluid">
      <div class="container-fluid">
        <div class="card">
          <div class="card-header border-0 pt-6">
            <div class="card-title">
              <div class="d-flex align-items-center position-relative my-1">
                <span class="svg-icon svg-icon-1 position-absolute ms-6">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x="17.0365"
                      y="15.1223"
                      width="8.15546"
                      height="2"
                      rx="1"
                      transform="rotate(45 17.0365 15.1223)"
                      fill="black"
                    />
                    <path
                      d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                      fill="black"
                    />
                  </svg>
                </span>
                <form @submit.prevent="search()">
                  <input
                    v-model="filter.search"
                    type="text"
                    class="form-control form-control-solid ps-14 me-3"
                    placeholder="Rechercher un permis"
                  />
                </form>
                <button
                  v-if="filter.search || permitsFilter.search"
                  type="button"
                  class="ms-3 btn btn-light-danger"
                  @click.prevent="resetSearch()"
                >
                  Supprimer la recherche
                </button>
              </div>
            </div>
            <div class="card-toolbar">
              <div class="d-flex justify-content-end">
                <div class="me-3 d-flex align-items-center">
                  <input
                    v-model="scrollValue"
                    type="range"
                    min="0"
                    :max="maxScroll"
                    class="form-range"
                    @input="scrollTable"
                  />
                </div>

                <div class="dropdown me-3">
                  <button
                    id="dropdownMenuButton1"
                    type="button"
                    class="btn btn-light-primary"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="false"
                    aria-expanded="false"
                  >
                    <span class="svg-icon svg-icon-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.3"
                          x="2"
                          y="2"
                          width="20"
                          height="20"
                          rx="5"
                          fill="black"
                        />
                        <path
                          d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                    Masquer des colonnes
                  </button>
                  <div
                    class="dropdown-menu menu menu-sub menu-sub-dropdown w-md-300px"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <div class="px-7 py-5">
                      <div class="fs-5 text-dark fw-bolder">Colonnes</div>
                    </div>
                    <div class="separator border-gray-200"></div>
                    <form class="px-7 py-5">
                      <div class="mb-10">
                        <div class="mb-3">
                          <input
                            id="num_dau_id"
                            v-model="column.num_dau"
                            type="checkbox"
                            class="form-check-input"
                          />
                          <label
                            class="form-label fw-bold ps-2"
                            for="num_dau_id"
                          >
                            NUM_DAU</label
                          >
                        </div>
                        <div class="mb-3">
                          <input
                            id="promoter_id"
                            v-model="column.promoter"
                            type="checkbox"
                            class="form-check-input"
                          />
                          <label
                            class="form-label fw-bold ps-2"
                            for="promoter_id"
                          >
                            PROMOTEUR</label
                          >
                        </div>
                        <div class="mb-3">
                          <input
                            id="contact_id"
                            v-model="column.contact"
                            type="checkbox"
                            class="form-check-input"
                          />
                          <label
                            class="form-label fw-bold ps-2"
                            for="contact_id"
                          >
                            CONTACT PRINCIPAL</label
                          >
                        </div>
                        <div class="mb-3">
                          <input
                            id="num_contact_id"
                            v-model="column.num_contact"
                            type="checkbox"
                            class="form-check-input"
                          />
                          <label
                            class="form-label fw-bold ps-2"
                            for="num_contact_id"
                          >
                            NUMÉRO DU CONTACT PRINCIPAL</label
                          >
                        </div>
                        <div class="mb-3">
                          <input
                            id="denom_dem_id"
                            v-model="column.denom_dem"
                            type="checkbox"
                            class="form-check-input"
                          />
                          <label
                            class="form-label fw-bold ps-2"
                            for="denom_dem_id"
                          >
                            DENOM DEM</label
                          >
                        </div>
                        <div class="mb-3">
                          <input
                            id="siren_dem_id"
                            v-model="column.siren_dem"
                            type="checkbox"
                            class="form-check-input"
                          />
                          <label
                            class="form-label fw-bold ps-2"
                            for="siren_dem_id"
                          >
                            SIREN DEM</label
                          >
                        </div>
                        <div class="mb-3">
                          <input
                            id="loc_dem_id"
                            v-model="column.localite_dem"
                            type="checkbox"
                            class="form-check-input"
                          />
                          <label
                            class="form-label fw-bold ps-2"
                            for="loc_dem_id"
                          >
                            LOCALITÉ DEMANDEUR</label
                          >
                        </div>
                        <div class="mb-3">
                          <input
                            id="address_id"
                            v-model="column.address"
                            type="checkbox"
                            class="form-check-input"
                          />
                          <label
                            class="form-label fw-bold ps-2"
                            for="address_id"
                          >
                            ADRESSE</label
                          >
                        </div>
                        <div class="mb-3">
                          <input
                            id="adr_loc_ter_id"
                            v-model="column.adr_localite_ter"
                            type="checkbox"
                            class="form-check-input"
                          />
                          <label
                            class="form-label fw-bold ps-2"
                            for="adr_loc_ter_id"
                          >
                            ADR LOCALITÉ TER</label
                          >
                        </div>
                        <div class="mb-3">
                          <input
                            id="adr_codpost_ter_id"
                            v-model="column.adr_codpost_ter"
                            type="checkbox"
                            class="form-check-input"
                          />
                          <label
                            class="form-label fw-bold ps-2"
                            for="adr_codpost_ter_id"
                          >
                            ADR CODPOST TER</label
                          >
                        </div>
                        <div class="mb-3">
                          <input
                            id="dep_id"
                            v-model="column.dep"
                            type="checkbox"
                            class="form-check-input"
                          />
                          <label class="form-label fw-bold ps-2" for="dep_id">
                            DEP</label
                          >
                        </div>
                        <div class="mb-3">
                          <input
                            id="nb_lgt_tot_crees_id"
                            v-model="column.nb_lgt_tot_crees"
                            type="checkbox"
                            class="form-check-input"
                          />
                          <label
                            class="form-label fw-bold ps-2"
                            for="nb_lgt_tot_crees_id"
                          >
                            NB LGT TOT CREES</label
                          >
                        </div>
                        <div class="mb-3">
                          <input
                            id="nb_lls_id"
                            v-model="column.nb_lls"
                            type="checkbox"
                            class="form-check-input"
                          />
                          <label
                            class="form-label fw-bold ps-2"
                            for="nb_lls_id"
                          >
                            NB LLS</label
                          >
                        </div>
                        <div class="mb-3">
                          <input
                            id="nb_lgt_acc_soc_hors_ptz_id"
                            v-model="column.nb_lgt_acc_soc_hors_ptz"
                            type="checkbox"
                            class="form-check-input"
                          />
                          <label
                            class="form-label fw-bold ps-2"
                            for="nb_lgt_acc_soc_hors_ptz_id"
                          >
                            NB LGT ACC SOC HORS PTZ</label
                          >
                        </div>
                        <div class="mb-3">
                          <input
                            id="decote_id"
                            v-model="column.decote"
                            type="checkbox"
                            class="form-check-input"
                          />
                          <label
                            class="form-label fw-bold ps-2"
                            for="decote_id"
                          >
                            DÉCOTE PRIX ANCIEN</label
                          >
                        </div>
                        <div class="mb-3">
                          <input
                            id="specific_id"
                            v-model="column.specific_case"
                            type="checkbox"
                            class="form-check-input"
                          />
                          <label
                            class="form-label fw-bold ps-2"
                            for="specific_id"
                          >
                            SITUATION DE L'OPÉRATION</label
                          >
                        </div>
                        <div class="mb-3">
                          <input
                            id="discussion_stage_id"
                            v-model="column.discussion_stage"
                            type="checkbox"
                            class="form-check-input"
                          />
                          <label
                            class="form-label fw-bold ps-2"
                            for="discussion_stage_id"
                          >
                            ÉTAPE DE LA DISCUSSION</label
                          >
                        </div>
                        <div class="mb-3">
                          <input
                            id="date_reelle_autorisation_id"
                            v-model="column.date_reelle_autorisation"
                            type="checkbox"
                            class="form-check-input"
                          />
                          <label
                            class="form-label fw-bold ps-2"
                            for="date_reelle_autorisation_id"
                          >
                            DATE RÉELLE AUTORISATION</label
                          >
                        </div>
                        <div class="mb-3">
                          <input
                            id="date_reelle_doc_id"
                            v-model="column.date_reelle_doc"
                            type="checkbox"
                            class="form-check-input"
                          />
                          <label
                            class="form-label fw-bold ps-2"
                            for="date_reelle_doc_id"
                          >
                            DATE RÉELLE DOC</label
                          >
                        </div>
                        <div class="mb-3">
                          <input
                            id="date_reelle_daact_id"
                            v-model="column.date_reelle_daact"
                            type="checkbox"
                            class="form-check-input"
                          />
                          <label
                            class="form-label fw-bold ps-2"
                            for="date_reelle_daact_id"
                          >
                            DATE RÉELLE DAACT</label
                          >
                        </div>
                        <div class="mb-3">
                          <input
                            id="proprio_permit_id"
                            v-model="column.proprio_permit"
                            type="checkbox"
                            class="form-check-input"
                          />
                          <label
                            class="form-label fw-bold ps-2"
                            for="proprio_permit_id"
                          >
                            PROPRIÉTAIRE DU PERMIS</label
                          >
                        </div>
                        <div class="mb-3">
                          <input
                            id="proprio_permit_id"
                            v-model="column.prix_immo_ancien_dvf_moyen"
                            type="checkbox"
                            class="form-check-input"
                          />
                          <label
                            class="form-label fw-bold ps-2"
                            for="proprio_permit_id"
                          >
                            PRIX IMMO ANCIEN DVF MOYEN</label
                          >
                        </div>
                        <div class="mb-3">
                          <input
                            id="proprio_permit_id"
                            v-model="column.nb_lls_decote"
                            type="checkbox"
                            class="form-check-input"
                          />
                          <label
                            class="form-label fw-bold ps-2"
                            for="proprio_permit_id"
                          >
                            NB LLS / DÉCOTE PRIX ANCIEN</label
                          >
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <PermitsFilters />
              </div>
            </div>
          </div>

          <!-- Loader -->
          <Loader :data="permits" />

          <div v-if="permits">
            <div v-if="permits.count > 0">
              <div class="card-body p-0">
                <div
                  ref="tableResponsive"
                  class="table-responsive"
                  @scroll="onTableScroll"
                >
                  <table
                    v-if="permits.results"
                    class="table table-flush align-middle table-row-bordered table-row-solid gy-4"
                  >
                    <div class="h-700px overflow-scroll">
                      <thead
                        style="
                          position: sticky;
                          top: 0;
                          z-index: 1;
                          background-color: white;
                        "
                      >
                        <tr
                          class="text-start fw-bolder fs-6 text-uppercase gs-0"
                        >
                          <th v-if="column.num_dau" class="min-w-125px ps-9">
                            <div
                              class="d-flex align-items-center justify-content-between"
                            >
                              Num_DAU
                              <span class="d-flex row" style="cursor: pointer">
                                <i
                                  class="bi bi-caret-up-fill"
                                  @click.prevent="ascendingNumDau()"
                                ></i>
                                <i
                                  class="bi bi-caret-down-fill"
                                  @click.prevent="descendingNumDau()"
                                ></i>
                              </span>
                            </div>
                          </th>
                          <th
                            v-if="column.nb_lls_decote"
                            class="min-w-125px ps-10"
                          >
                            Nb LLS / Décote prix ancien
                          </th>
                          <th v-if="column.promoter" class="min-w-150px ps-9">
                            <div
                              class="d-flex align-items-center justify-content-between"
                            >
                              Promoteur
                              <span class="d-flex row" style="cursor: pointer">
                                <i
                                  class="bi bi-caret-up-fill"
                                  @click.prevent="ascendingPromoter()"
                                ></i>
                                <i
                                  class="bi bi-caret-down-fill"
                                  @click.prevent="descendingPromoter()"
                                ></i>
                              </span>
                            </div>
                          </th>
                          <th v-if="column.contact" class="min-w-175px ps-9">
                            <div
                              class="d-flex align-items-center justify-content-between"
                            >
                              Contact principal
                              <span class="d-flex row" style="cursor: pointer">
                                <i
                                  class="bi bi-caret-up-fill"
                                  @click.prevent="ascendingPrincipalContact()"
                                ></i>
                                <i
                                  class="bi bi-caret-down-fill"
                                  @click.prevent="descendingPrincipalContact()"
                                ></i>
                              </span>
                            </div>
                          </th>
                          <th
                            v-if="column.num_contact"
                            class="min-w-125px ps-9"
                          >
                            NUM du contact principal
                          </th>
                          <th v-if="column.denom_dem" class="min-w-125px ps-9">
                            <div
                              class="d-flex align-items-center justify-content-between"
                            >
                              DENOM DEM
                              <span class="d-flex row" style="cursor: pointer">
                                <i
                                  class="bi bi-caret-up-fill"
                                  @click.prevent="ascendingDenomDem()"
                                ></i>
                                <i
                                  class="bi bi-caret-down-fill"
                                  @click.prevent="descendingDenomDem()"
                                ></i>
                              </span>
                            </div>
                          </th>
                          <th v-if="column.siren_dem" class="min-w-125px ps-9">
                            <div
                              class="d-flex align-items-center justify-content-between"
                            >
                              SIREN DEM
                              <span class="d-flex row" style="cursor: pointer">
                                <i
                                  class="bi bi-caret-up-fill"
                                  @click.prevent="ascendingSirenDem()"
                                ></i>
                                <i
                                  class="bi bi-caret-down-fill"
                                  @click.prevent="descendingSirenDem()"
                                ></i>
                              </span>
                            </div>
                          </th>
                          <th
                            v-if="column.localite_dem"
                            class="min-w-125px ps-9"
                          >
                            <div
                              class="d-flex align-items-center justify-content-between"
                            >
                              Localité demandeur
                              <span class="d-flex row" style="cursor: pointer">
                                <i
                                  class="bi bi-caret-up-fill"
                                  @click.prevent="ascendingLocaliteDem()"
                                ></i>
                                <i
                                  class="bi bi-caret-down-fill"
                                  @click.prevent="descendingLocaliteDem()"
                                ></i>
                              </span>
                            </div>
                          </th>
                          <th v-if="column.address" class="min-w-125px ps-9">
                            Adresse
                          </th>
                          <th
                            v-if="column.adr_localite_ter"
                            class="min-w-150px ps-9"
                          >
                            <div
                              class="d-flex align-items-center justify-content-between"
                            >
                              ADR LOCALITE TER
                              <span class="d-flex row" style="cursor: pointer">
                                <i
                                  class="bi bi-caret-up-fill"
                                  @click.prevent="ascendingAdrLocaliteTer()"
                                ></i>
                                <i
                                  class="bi bi-caret-down-fill"
                                  @click.prevent="descendingAdrLocaliteTer()"
                                ></i>
                              </span>
                            </div>
                          </th>
                          <th
                            v-if="column.adr_codpost_ter"
                            class="min-w-125px ps-9"
                          >
                            <div
                              class="d-flex align-items-center justify-content-between"
                            >
                              ADR CODPOST TER
                              <span class="d-flex row" style="cursor: pointer">
                                <i
                                  class="bi bi-caret-up-fill"
                                  @click.prevent="ascendingAdrCodpostTer()"
                                ></i>
                                <i
                                  class="bi bi-caret-down-fill"
                                  @click.prevent="descendingAdrCodpostTer()"
                                ></i>
                              </span>
                            </div>
                          </th>
                          <th v-if="column.dep" class="min-w-100px ps-9">
                            <div
                              class="d-flex align-items-center justify-content-between"
                            >
                              DEP
                              <span class="d-flex row" style="cursor: pointer">
                                <i
                                  class="bi bi-caret-up-fill"
                                  @click.prevent="ascendingDep()"
                                ></i>
                                <i
                                  class="bi bi-caret-down-fill"
                                  @click.prevent="descendingDep()"
                                ></i>
                              </span>
                            </div>
                          </th>
                          <th
                            v-if="column.nb_lgt_tot_crees"
                            class="min-w-125px ps-9"
                          >
                            <div
                              class="d-flex align-items-center justify-content-between"
                            >
                              NB LGT TOT CREES
                              <span class="d-flex row" style="cursor: pointer">
                                <i
                                  class="bi bi-caret-up-fill"
                                  @click.prevent="ascendingNbLgtTotCrees()"
                                ></i>
                                <i
                                  class="bi bi-caret-down-fill"
                                  @click.prevent="descendingNbLgtTotCrees()"
                                ></i>
                              </span>
                            </div>
                          </th>
                          <th v-if="column.nb_lls" class="min-w-100px ps-9">
                            <div
                              class="d-flex align-items-center justify-content-between"
                            >
                              Nb LLS
                              <span class="d-flex row" style="cursor: pointer">
                                <i
                                  class="bi bi-caret-up-fill"
                                  @click.prevent="ascendingNblls()"
                                ></i>
                                <i
                                  class="bi bi-caret-down-fill"
                                  @click.prevent="descendingNblls()"
                                ></i>
                              </span>
                            </div>
                          </th>
                          <th
                            v-if="column.nb_lgt_acc_soc_hors_ptz"
                            class="min-w-150px ps-9"
                          >
                            <div
                              class="d-flex align-items-center justify-content-between"
                            >
                              NB LGT ACC SOC HORS PTZ
                              <span class="d-flex row" style="cursor: pointer">
                                <i
                                  class="bi bi-caret-up-fill"
                                  @click.prevent="ascendingNbLgtAccSocHorsPtz()"
                                ></i>
                                <i
                                  class="bi bi-caret-down-fill"
                                  @click.prevent="
                                    descendingNbLgtAccSocHorsPtz()
                                  "
                                ></i>
                              </span>
                            </div>
                          </th>
                          <th v-if="column.decote" class="min-w-125px ps-9">
                            Décote prix ancien
                          </th>
                          <th
                            v-if="column.prix_immo_ancien_dvf_moyen"
                            class="min-w-125px ps-9"
                          >
                            Prix immo ancien dvf moyen
                          </th>
                          <th
                            v-if="column.specific_case"
                            class="min-w-150px ps-9"
                          >
                            <div
                              class="d-flex align-items-center justify-content-between"
                            >
                              Situation de l'opération
                              <span class="d-flex row" style="cursor: pointer">
                                <i
                                  class="bi bi-caret-up-fill"
                                  @click.prevent="ascendingSpecificCase()"
                                ></i>
                                <i
                                  class="bi bi-caret-down-fill"
                                  @click.prevent="descendingSpecificCase()"
                                ></i>
                              </span>
                            </div>
                          </th>
                          <th
                            v-if="column.discussion_stage"
                            class="min-w-125px ps-9"
                          >
                            <div
                              class="d-flex align-items-center justify-content-between"
                            >
                              Étape de la discussion
                              <span class="d-flex row" style="cursor: pointer">
                                <i
                                  class="bi bi-caret-up-fill"
                                  @click.prevent="ascendingDiscussionStage()"
                                ></i>
                                <i
                                  class="bi bi-caret-down-fill"
                                  @click.prevent="descendingDiscussionStage()"
                                ></i>
                              </span>
                            </div>
                          </th>
                          <th
                            v-if="column.date_reelle_autorisation"
                            class="min-w-125px ps-9"
                          >
                            <div
                              class="d-flex align-items-center justify-content-between"
                            >
                              Date réelle autorisation
                              <span class="d-flex row" style="cursor: pointer">
                                <i
                                  class="bi bi-caret-up-fill"
                                  @click.prevent="
                                    ascendingDateReelleAutorisation()
                                  "
                                ></i>
                                <i
                                  class="bi bi-caret-down-fill"
                                  @click.prevent="
                                    descendingDateReelleAutorisation()
                                  "
                                ></i>
                              </span>
                            </div>
                          </th>
                          <th
                            v-if="column.date_reelle_doc"
                            class="min-w-125px ps-9"
                          >
                            <div
                              class="d-flex align-items-center justify-content-between"
                            >
                              Date réelle doc
                              <span class="d-flex row" style="cursor: pointer">
                                <i
                                  class="bi bi-caret-up-fill"
                                  @click.prevent="ascendingDateReelleDoc()"
                                ></i>
                                <i
                                  class="bi bi-caret-down-fill"
                                  @click.prevent="descendingDateReelleDoc()"
                                ></i>
                              </span>
                            </div>
                          </th>
                          <th
                            v-if="column.date_reelle_daact"
                            class="min-w-125px ps-9"
                          >
                            <div
                              class="d-flex align-items-center justify-content-between"
                            >
                              Date réelle daact
                              <span class="d-flex row" style="cursor: pointer">
                                <i
                                  class="bi bi-caret-up-fill"
                                  @click.prevent="ascendingDateReelleDaact()"
                                ></i>
                                <i
                                  class="bi bi-caret-down-fill"
                                  @click.prevent="descendingDateReelleDaact()"
                                ></i>
                              </span>
                            </div>
                          </th>
                          <th
                            v-if="column.proprio_permit"
                            class="min-w-125px ps-9"
                          >
                            <div
                              class="d-flex align-items-center justify-content-between"
                            >
                              Référents du permis
                              <span class="d-flex row" style="cursor: pointer">
                                <i
                                  class="bi bi-caret-up-fill"
                                  @click.prevent="ascendingProprioPermit()"
                                ></i>
                                <i
                                  class="bi bi-caret-down-fill"
                                  @click.prevent="descendingProprioPermit()"
                                ></i>
                              </span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody class="fw-bold">
                        <tr
                          v-for="permit in permits.results"
                          :key="permit.id"
                          :class="
                            permit.specific_case &&
                            (permit.specific_case ==
                              'Bailleur social ou assimilé' ||
                              permit.specific_case == 'LLS vendus' ||
                              permit.specific_case == 'Opération achevée' ||
                              permit.specific_case == 'Opération abandonnée' ||
                              permit.specific_case == 'Pas compétitifs' ||
                              permit.specific_case == 'Pas de LLS' ||
                              permit.specific_case == 'Autre non pertinent' ||
                              permit.specific_case == 'Promoteur indélicat' ||
                              permit.specific_case == 'Opération signée') &&
                            'bg-light-danger'
                          "
                        >
                          <td v-if="column.num_dau" class="min-w-125px ps-10">
                            <router-link :to="'/permits/' + permit.id">
                              <span v-if="permit.num_dau" class="text-black">
                                {{ permit.num_dau }}
                              </span>
                              <span v-else class="text-black-50">
                                Information non fournie
                              </span>
                            </router-link>
                          </td>
                          <td
                            v-if="column.nb_lls_decote"
                            class="min-w-125px ps-10"
                          >
                            <router-link :to="'/permits/' + permit.id">
                              <span
                                v-if="
                                  permit.nb_lls &&
                                  permit.city &&
                                  permit.city.decote_prix_ancien
                                "
                                class="text-black"
                              >
                                {{ permit.nb_lls }} /
                                {{ permit.city.decote_prix_ancien }}
                              </span>
                              <span v-else class="text-black-50">
                                Information non fournie
                              </span></router-link
                            >
                          </td>
                          <td v-if="column.promoter" class="min-w-125px ps-10">
                            <router-link :to="'/permits/' + permit.id">
                              <span
                                v-if="
                                  permit.specific_case ===
                                    'Bailleur social ou assimilé' ||
                                  permit.specific_case === 'Introuvable' ||
                                  permit.specific_case === 'Autre non pertinent'
                                "
                                class="text-danger"
                              >
                                {{ permit.specific_case }}
                              </span>
                              <span
                                v-else-if="permit.principal_contact"
                                class="text-black"
                              >
                                <span
                                  v-if="permit.principal_contact.promoter_name"
                                >
                                  {{
                                    permit.principal_contact.promoter_name
                                  }}</span
                                >
                              </span>
                              <span v-else class="text-black-50">
                                Information non fournie
                              </span></router-link
                            >
                          </td>
                          <td v-if="column.contact" class="min-w-175px ps-10">
                            <router-link :to="'/permits/' + permit.id">
                              <span
                                v-if="
                                  permit.specific_case ===
                                    'Bailleur social ou assimilé' ||
                                  permit.specific_case === 'Introuvable' ||
                                  permit.specific_case === 'Autre non pertinent'
                                "
                                class="text-danger"
                              >
                                {{ permit.specific_case }}
                              </span>
                              <span
                                v-else-if="permit.principal_contact"
                                class="d-flex align-items-center justify-content-between text-black"
                              >
                                <span
                                  v-if="
                                    permit.principal_contact.first_name &&
                                    permit.principal_contact.last_name
                                  "
                                  class="me-1"
                                >
                                  {{ permit.principal_contact.first_name }}
                                  {{ permit.principal_contact.last_name }}</span
                                >
                                <span
                                  v-if="
                                    permit.principal_contact.relation_quality
                                  "
                                  class="ms-2 badge bg-light-warning text-dark"
                                >
                                  {{
                                    permit.principal_contact.relation_quality
                                  }}
                                  <i class="bi bi-star-fill text-warning h6"></i
                                ></span>
                              </span>
                              <span v-else class="text-black-50">
                                Information non fournie
                              </span></router-link
                            >
                          </td>

                          <td
                            v-if="column.num_contact"
                            class="min-w-125px ps-10"
                          >
                            <router-link :to="'/permits/' + permit.id">
                              <span
                                v-if="permit.principal_contact"
                                class="text-black"
                              >
                                <span
                                  v-if="permit.principal_contact.phone_number"
                                >
                                  {{
                                    permit.principal_contact.phone_number
                                  }}</span
                                >
                                <span v-else class="text-black-50">
                                  Information non fournie
                                </span>
                              </span>
                              <span v-else class="text-black-50">
                                Information non fournie
                              </span></router-link
                            >
                          </td>
                          <td v-if="column.denom_dem" class="min-w-125px ps-10">
                            <router-link :to="'/permits/' + permit.id">
                              <span v-if="permit.denom_dem" class="text-black">
                                {{ permit.denom_dem }}
                              </span>
                              <span v-else class="text-black-50">
                                Information non fournie
                              </span>
                            </router-link>
                          </td>
                          <td v-if="column.siren_dem" class="min-w-125px ps-10">
                            <router-link :to="'/permits/' + permit.id">
                              <span v-if="permit.siren_dem" class="text-black">
                                {{ permit.siren_dem }}
                              </span>
                              <span v-else class="text-black-50">
                                Information non fournie
                              </span></router-link
                            >
                          </td>
                          <td
                            v-if="column.localite_dem"
                            class="min-w-125px ps-10"
                          >
                            <router-link :to="'/permits/' + permit.id">
                              <span
                                v-if="permit.localite_dem"
                                class="text-black"
                              >
                                {{ permit.localite_dem }}
                              </span>
                              <span v-else class="text-black-50">
                                Information non fournie
                              </span></router-link
                            >
                          </td>
                          <td v-if="column.address" class="min-w-125px ps-10">
                            <router-link :to="'/permits/' + permit.id">
                              <span
                                v-if="
                                  permit.adr_num_ter ||
                                  permit.adr_typevoie_ter ||
                                  permit.adr_libvoie_ter
                                "
                                class="text-black"
                              >
                                <span v-if="permit.adr_num_ter" class="mr-1">{{
                                  permit.adr_num_ter + " "
                                }}</span>
                                <span
                                  v-if="permit.adr_typevoie_ter"
                                  class="mr-1"
                                  >{{ permit.adr_typevoie_ter + " " }}</span
                                >
                                <span
                                  v-if="permit.adr_libvoie_ter"
                                  class="mr-1"
                                  >{{ permit.adr_libvoie_ter }}</span
                                >
                              </span>
                              <span v-else class="text-black-50">
                                Information non fournie</span
                              ></router-link
                            >
                          </td>
                          <td
                            v-if="column.adr_localite_ter"
                            class="min-w-150px ps-10"
                          >
                            <router-link :to="'/permits/' + permit.id">
                              <span
                                v-if="permit.adr_localite_ter"
                                class="text-black"
                              >
                                {{ permit.adr_localite_ter }}
                              </span>
                              <span v-else class="text-black-50">
                                Information non fournie
                              </span></router-link
                            >
                          </td>
                          <td
                            v-if="column.adr_codpost_ter"
                            class="min-w-125px ps-10"
                          >
                            <router-link :to="'/permits/' + permit.id">
                              <span
                                v-if="permit.adr_codpost_ter"
                                class="text-black"
                              >
                                {{ permit.adr_codpost_ter }}
                              </span>
                              <span v-else class="text-black-50">
                                Information non fournie
                              </span></router-link
                            >
                          </td>
                          <td v-if="column.dep" class="min-w-100px ps-10">
                            <router-link :to="'/permits/' + permit.id">
                              <span v-if="permit.dep" class="text-black">
                                {{ permit.dep }}
                              </span>
                              <span v-else class="text-black-50">
                                Information non fournie
                              </span></router-link
                            >
                          </td>
                          <td
                            v-if="column.nb_lgt_tot_crees"
                            class="min-w-125px ps-10"
                          >
                            <router-link :to="'/permits/' + permit.id">
                              <span
                                v-if="permit.nb_lgt_tot_crees"
                                class="text-black"
                              >
                                {{ permit.nb_lgt_tot_crees }}
                              </span>
                              <span v-else class="text-black-50">
                                Information non fournie
                              </span></router-link
                            >
                          </td>
                          <td v-if="column.nb_lls" class="min-w-100px ps-10">
                            <router-link :to="'/permits/' + permit.id">
                              <span v-if="permit.nb_lls" class="text-black">
                                {{ permit.nb_lls }}
                              </span>
                              <span v-else class="text-black-50">
                                Information non fournie
                              </span></router-link
                            >
                          </td>
                          <td
                            v-if="column.nb_lgt_acc_soc_hors_ptz"
                            class="min-w-125px ps-10"
                          >
                            <router-link :to="'/permits/' + permit.id">
                              <span
                                v-if="permit.nb_lgt_acc_soc_hors_ptz"
                                class="text-black"
                              >
                                {{ permit.nb_lgt_acc_soc_hors_ptz }}
                              </span>
                              <span v-else class="text-black-50">
                                Information non fournie
                              </span></router-link
                            >
                          </td>
                          <td v-if="column.decote" class="min-w-125px ps-10">
                            <router-link :to="'/permits/' + permit.id">
                              <span
                                v-if="permit.city.decote_prix_ancien"
                                class="text-black"
                                >{{
                                  Math.round(
                                    permit.city.decote_prix_ancien * 100
                                  )
                                }}
                                %
                              </span>
                              <span v-else class="text-black-50">
                                Information non fournie
                              </span></router-link
                            >
                          </td>
                          <td class="min-w-125px ps-10">
                            <router-link :to="'/permits/' + permit.id">
                              <span
                                v-if="permit.city.prix_immo_ancien_dvf_moyen"
                                class="text-black"
                              >
                                {{ permit.city.prix_immo_ancien_dvf_moyen }}
                              </span>
                              <span v-else class="text-black-50">
                                Information non fournie
                              </span></router-link
                            >
                          </td>
                          <td
                            v-if="column.specific_case"
                            class="min-w-125px ps-10"
                          >
                            <router-link :to="'/permits/' + permit.id">
                              <span v-if="permit.specific_case">
                                <span
                                  v-if="
                                    permit.specific_case === 'Permis favoris'
                                  "
                                  class="badge badge-light-warning text-warning"
                                >
                                  {{ permit.specific_case }}
                                </span>
                                <span
                                  v-else-if="
                                    permit.specific_case ===
                                      'Bailleur social ou assimilé' ||
                                    permit.specific_case === 'Introuvable' ||
                                    permit.specific_case ===
                                      'Autre non pertinent'
                                  "
                                  class="badge bg-light-danger text-danger"
                                >
                                  {{ permit.specific_case }}
                                </span>
                                <span v-else class="text-dark">
                                  {{ permit.specific_case }}
                                </span>
                              </span>
                              <span v-else class="text-black-50">
                                Information non fournie
                              </span></router-link
                            >
                          </td>
                          <td
                            v-if="column.discussion_stage"
                            class="min-w-125px ps-10"
                          >
                            <router-link :to="'/permits/' + permit.id">
                              <span
                                v-if="permit.discussion_stage"
                                class="text-black"
                              >
                                {{ permit.discussion_stage }}
                              </span>
                              <span v-else class="text-black-50">
                                Information non fournie
                              </span></router-link
                            >
                          </td>
                          <td
                            v-if="column.date_reelle_autorisation"
                            class="min-w-125px ps-10"
                          >
                            <router-link :to="'/permits/' + permit.id">
                              <span
                                v-if="permit.date_reelle_autorisation"
                                class="text-black"
                              >
                                {{
                                  getMomentDate(permit.date_reelle_autorisation)
                                }}
                              </span>
                              <span v-else class="text-black-50">
                                Information non fournie
                              </span></router-link
                            >
                          </td>
                          <td
                            v-if="column.date_reelle_doc"
                            class="min-w-125px ps-10"
                          >
                            <router-link :to="'/permits/' + permit.id">
                              <span
                                v-if="permit.date_reelle_doc"
                                class="text-black"
                              >
                                {{ getMomentDate(permit.date_reelle_doc) }}
                              </span>
                              <span v-else class="text-black-50">
                                Information non fournie
                              </span></router-link
                            >
                          </td>
                          <td
                            v-if="column.date_reelle_daact"
                            class="min-w-125px ps-10"
                          >
                            <router-link :to="'/permits/' + permit.id">
                              <span
                                v-if="permit.date_reelle_daact"
                                class="text-black"
                              >
                                {{ getMomentDate(permit.date_reelle_daact) }}
                              </span>
                              <span v-else class="text-black-50">
                                Information non fournie
                              </span></router-link
                            >
                          </td>
                          <td
                            v-if="column.proprio_permit"
                            class="min-w-125px ps-10"
                          >
                            <router-link :to="'/permits/' + permit.id">
                              <span
                                v-if="permit.referrers.length > 0"
                                class="text-black"
                              >
                                <span
                                  v-for="referrer in permit.referrers"
                                  :key="referrer.id"
                                >
                                  <span>
                                    {{ referrer.first_name + " " }}
                                    {{ referrer.last_name }}<br />
                                  </span>
                                </span>
                              </span>
                              <span v-else class="text-black-50">
                                Information non fournie
                              </span></router-link
                            >
                          </td>
                        </tr>
                      </tbody>
                    </div>
                  </table>
                </div>
              </div>
              <div v-if="permits" class="card-footer">
                <div
                  class="row justify-content-center justify-content-sm-between align-items-sm-center"
                >
                  <TableFooter
                    v-if="permits.count"
                    :all-data="permits"
                    :default-size="30"
                    :page="filter.page"
                    :has-query="true"
                    @selectedSize="getSizeSelected"
                    @selectedPage="getPageSelected"
                    @nextPage="showNextPermits(permits.next)"
                    @previousPage="showPreviousPermits(permits.previous)"
                    @change="getAllPermits"
                  />
                </div>
              </div>
            </div>
            <div v-else class="card-body ms-1">
              <div class="alert alert-secondary">
                <div class="d-flex flex-column">
                  <span class="mb-1 text-dark">Aucun permis enregistré</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Loader from "@/components/Loader";
import PermitsFilters from "@/components/permits/PermitsFilters";
import TableFooter from "@/components/TableFooter";
import store from "@/store";
import moment from "moment";

export default {
  name: "Permits",
  components: {
    Loader,
    PermitsFilters,
    TableFooter,
  },
  data() {
    return {
      filter: {
        adr_localite_ter: "",
        date_reelle_autorisation__gte: "",
        date_reelle_autorisation__lte: "",
        date_reelle_daact__isnull: "",
        date_reelle_doc__isnull: "",
        dep__in: "",
        dep: "",
        city__decote_prix_ancien__gt: "",
        city__decote_prix_ancien__lt: "",
        etat_dau: "",
        localite_dem: "",
        nb_lgt_tot_crees__gte: "",
        nb_lgt_tot_crees__lte: "",
        nb_lls__gte: "",
        nb_lls__lte: "",
        nb_lgt_acc_soc_hors_ptz_gte: "",
        nb_lgt_acc_soc_hors_ptz_lte: "",
        nb_lls__gt: "",
        nb_lls__lt: false,
        num_dau: "",
        order: "",
        referrers: "",
        specific_case: "",
        specific_case__in: "",
        type_dau: "",
        search: "",
        proprio_permit_id: "",
        size: "",
        defaultSize: 30,
        page: "",
      },
      nbLls0IsActive: true,
      decotePrixAncienGtIsActive: true,
      favouritesIsActive: false,
      ownerIsActive: false,
      outOfScopeIsActive: false,
      column: {
        num_dau: true,
        promoter: true,
        contact: true,
        num_contact: true,
        denom_dem: true,
        siren_dem: true,
        localite_dem: true,
        address: true,
        adr_localite_ter: true,
        adr_codpost_ter: true,
        dep: true,
        nb_lgt_tot_crees: true,
        nb_lls: true,
        decote: true,
        specific_case: true,
        discussion_stage: true,
        date_reelle_autorisation: true,
        date_reelle_doc: true,
        date_reelle_daact: true,
        proprio_permit: true,
        prix_immo_ancien_dvf_moyen: true,
        nb_lls_decote: true,
        nb_lgt_acc_soc_hors_ptz: true,
      },
      scrollValue: 0,
      maxScroll: 100,
    };
  },
  computed: {
    ...mapGetters(["permits"]),
    ...mapGetters(["permitsFilter"]),
  },
  methods: {
    ...mapActions(["getPermits"]),
    ...mapActions(["nextPaginate"]),
    ...mapActions(["previousPaginate"]),
    async getSizeSelected(res) {
      this.filter.size = res.sizeSelected;
    },
    async getPageSelected(res) {
      this.filter.page = res.currentPage;
    },
    getAllPermits() {
      this.permitsFilter.size = this.filter.size;
      this.permitsFilter.defaultSize = this.filter.defaultSize;
      this.permitsFilter.page = this.filter.page;
      this.getPermits(this.permitsFilter);
      this.permitsData = this.permits;
    },
    async search() {
      this.permitsFilter.search = this.filter.search;
      this.getAllPermits(this.permitsFilter.search);
    },
    async resetSearch() {
      this.filter.search = "";
      this.permitsFilter.search = "";
      this.getAllPermits(this.permitsFilter.search);
    },
    // Filters
    filterLLS0() {
      this.permitsFilter.nb_lls__gt = 0;
      this.nbLls0IsActive = true;
      this.getAllPermits();
    },
    deleteFilterLLS0() {
      this.permitsFilter.nb_lls__gt = false;
      this.nbLls0IsActive = false;
      this.getAllPermits();
    },
    filterDecotePrixAncienGt() {
      this.permitsFilter.city__decote_prix_ancien__gt = 0.2;
      this.decotePrixAncienGtIsActive = true;
      this.getAllPermits();
    },
    deleteFilterDecotePrixAncienGt() {
      this.decotePrixAncienGtIsActive = false;
      this.permitsFilter.city__decote_prix_ancien__gt = "";
      this.getAllPermits();
    },
    filterFavourites() {
      this.permitsFilter.specific_case = "Permis favoris";
      this.favouritesIsActive = true;
      this.getAllPermits();
    },
    deleteFilterFavourites() {
      this.permitsFilter.specific_case = "";
      this.favouritesIsActive = false;
      this.getAllPermits();
    },
    filterOwner() {
      this.permitsFilter.referrers = store.state.userAuth.user_id;
      this.ownerIsActive = true;
      this.getAllPermits();
    },
    deleteFilterOwner() {
      this.permitsFilter.referrers = "";
      this.ownerIsActive = false;
      this.getAllPermits();
    },
    filterOutOfScope() {
      this.permitsFilter.specific_case__in =
        "Bailleur social ou assimilé,LLS vendu,Opération achevée,Opération abandonnée,Pas de LLS,Pas compétifs,Autre non pertinent";
      this.outOfScopeIsActive = true;
      this.getAllPermits();
    },
    deleteFilterOutOfScope() {
      this.permitsFilter.specific_case__in = "";
      this.outOfScopeIsActive = false;
      this.getAllPermits();
    },
    // Delete filters
    deleteAllFilters() {
      // Delete filters value
      this.permitsFilter.adr_localite_ter = "";
      this.permitsFilter.city__decote_prix_ancien__gt = 0.2;
      this.permitsFilter.city__decote_prix_ancien__lt = "";
      this.permitsFilter.date_reelle_autorisation__gte = "";
      this.permitsFilter.date_reelle_doc__isnull = "";
      this.permitsFilter.date_reelle_daact__isnull = "";
      this.permitsFilter.date_reelle_doc__isnull = "";
      this.permitsFilter.dep = "";
      this.permitsFilter.etat_dau = "";
      this.permitsFilter.localite_dem = "";
      this.permitsFilter.nb_lgt_tot_crees__gte = "";
      this.permitsFilter.nb_lgt_tot_crees__lte = "";
      this.permitsFilter.nb_lls = "";
      this.permitsFilter.nb_lls__gt = 0;
      this.permitsFilter.nb_lls__lt = false;
      this.permitsFilter.nb_lls__gte = "";
      this.permitsFilter.nb_lls__lte = "";
      this.permitsFilter.nb_lgt_acc_soc_hors_ptz_gte = "";
      this.permitsFilter.nb_lgt_acc_soc_hors_ptz_lte = "";
      this.permitsFilter.order = "";
      this.permitsFilter.referrers = "";
      this.permitsFilter.specific_case = "";
      this.permitsFilter.specific_case__in = "";
      this.permitsFilter.type_dau = "";
      // Delete buttons color
      this.favouritesIsActive = false;
      this.ownerIsActive = false;
      this.outOfScopeIsActive = false;
      this.getAllPermits();
    },
    // Ordering
    ascendingNumDau() {
      this.permitsFilter.order = "num_dau";
      store.commit("permitsFilter", this.permitsFilter);
      this.getAllPermits();
    },
    descendingNumDau() {
      this.permitsFilter.order = "-num_dau";
      store.commit("permitsFilter", this.permitsFilter);
      this.getAllPermits();
    },
    ascendingPromoter() {
      this.permitsFilter.order = "principal_contact__promoter";
      store.commit("permitsFilter", this.permitsFilter);
      this.getAllPermits();
    },
    descendingPromoter() {
      this.permitsFilter.order = "-principal_contact__promoter";
      store.commit("permitsFilter", this.permitsFilter);
      this.getAllPermits();
    },
    ascendingPrincipalContact() {
      this.permitsFilter.order = "principal_contact";
      store.commit("permitsFilter", this.permitsFilter);
      this.getAllPermits();
    },
    descendingPrincipalContact() {
      this.permitsFilter.order = "-principal_contact";
      store.commit("permitsFilter", this.permitsFilter);
      this.getAllPermits();
    },
    ascendingDenomDem() {
      this.permitsFilter.order = "denom_dem";
      store.commit("permitsFilter", this.permitsFilter);
      this.getAllPermits();
    },
    descendingDenomDem() {
      this.permitsFilter.order = "-denom_dem";
      store.commit("permitsFilter", this.permitsFilter);
      this.getAllPermits();
    },
    ascendingSirenDem() {
      this.permitsFilter.order = "siren_dem";
      store.commit("permitsFilter", this.permitsFilter);
      this.getAllPermits();
    },
    descendingSirenDem() {
      this.permitsFilter.order = "-siren_dem";
      store.commit("permitsFilter", this.permitsFilter);
      this.getAllPermits();
    },
    ascendingLocaliteDem() {
      this.permitsFilter.order = "localite_dem";
      store.commit("permitsFilter", this.permitsFilter);
      this.getAllPermits();
    },
    descendingLocaliteDem() {
      this.permitsFilter.order = "-localite_dem";
      store.commit("permitsFilter", this.permitsFilter);
      this.getAllPermits();
    },
    ascendingAdrLocaliteTer() {
      this.permitsFilter.order = "adr_localite_ter";
      store.commit("permitsFilter", this.permitsFilter);
      this.getAllPermits();
    },
    descendingAdrLocaliteTer() {
      this.permitsFilter.order = "-adr_localite_ter";
      store.commit("permitsFilter", this.permitsFilter);
      this.getAllPermits();
    },
    ascendingAdrCodpostTer() {
      this.permitsFilter.order = "adr_codpost_ter";
      store.commit("permitsFilter", this.permitsFilter);
      this.getAllPermits();
    },
    descendingAdrCodpostTer() {
      this.permitsFilter.order = "-adr_codpost_ter";
      store.commit("permitsFilter", this.permitsFilter);
      this.getAllPermits();
    },
    ascendingDep() {
      this.permitsFilter.order = "dep";
      store.commit("permitsFilter", this.permitsFilter);
      this.getAllPermits();
    },
    descendingDep() {
      this.permitsFilter.order = "-dep";
      store.commit("permitsFilter", this.permitsFilter);
      this.getAllPermits();
    },
    ascendingNbLgtTotCrees() {
      this.permitsFilter.order = "nb_lgt_tot_crees";
      store.commit("permitsFilter", this.permitsFilter);
      this.getAllPermits();
    },
    descendingNbLgtTotCrees() {
      this.permitsFilter.order = "-nb_lgt_tot_crees";
      store.commit("permitsFilter", this.permitsFilter);
      this.getAllPermits();
    },
    ascendingNblls() {
      this.permitsFilter.order = "nb_lls";
      store.commit("permitsFilter", this.permitsFilter);
      this.getAllPermits();
    },
    descendingNblls() {
      this.permitsFilter.order = "-nb_lls";
      store.commit("permitsFilter", this.permitsFilter);
      this.getAllPermits();
    },
    ascendingNbLgtAccSocHorsPtz() {
      this.permitsFilter.order = "nb_lgt_acc_soc_hors_ptz";
      store.commit("permitsFilter", this.permitsFilter);
      this.getAllPermits();
    },
    descendingNbLgtAccSocHorsPtz() {
      this.permitsFilter.order = "-nb_lgt_acc_soc_hors_ptz";
      store.commit("permitsFilter", this.permitsFilter);
      this.getAllPermits();
    },
    ascendingSpecificCase() {
      this.permitsFilter.order = "specific_case";
      store.commit("permitsFilter", this.permitsFilter);
      this.getAllPermits();
    },
    descendingSpecificCase() {
      this.permitsFilter.order = "-specific_case";
      store.commit("permitsFilter", this.permitsFilter);
      this.getAllPermits();
    },
    ascendingDiscussionStage() {
      this.permitsFilter.order = "discussion_stage";
      store.commit("permitsFilter", this.permitsFilter);
      this.getAllPermits();
    },
    descendingDiscussionStage() {
      this.permitsFilter.order = "-discussion_stage";
      store.commit("permitsFilter", this.permitsFilter);
      this.getAllPermits();
    },
    ascendingDateReelleAutorisation() {
      this.permitsFilter.order = "date_reelle_autorisation";
      store.commit("permitsFilter", this.permitsFilter);
      this.getAllPermits();
    },
    descendingDateReelleAutorisation() {
      this.permitsFilter.order = "-date_reelle_autorisation";
      store.commit("permitsFilter", this.permitsFilter);
      this.getAllPermits();
    },
    ascendingDateReelleDoc() {
      this.permitsFilter.order = "date_reelle_doc";
      store.commit("permitsFilter", this.permitsFilter);
      this.getAllPermits();
    },
    descendingDateReelleDoc() {
      this.permitsFilter.order = "-date_reelle_doc";
      store.commit("permitsFilter", this.permitsFilter);
      this.getAllPermits();
    },
    ascendingDateReelleDaact() {
      this.permitsFilter.order = "date_reelle_daact";
      store.commit("permitsFilter", this.permitsFilter);
      this.getAllPermits();
    },
    descendingDateReelleDaact() {
      this.permitsFilter.order = "-date_reelle_daact";
      store.commit("permitsFilter", this.permitsFilter);
      this.getAllPermits();
    },
    ascendingProprioPermit() {
      this.permitsFilter.order = "referrers";
      store.commit("permitsFilter", this.permitsFilter);
      this.getAllPermits();
    },
    descendingProprioPermit() {
      this.permitsFilter.order = "-referrers";
      store.commit("permitsFilter", this.permitsFilter);
      this.getAllPermits();
    },
    // Paginations
    async showNextPermits(url) {
      this.permitsData = await this.nextPaginate(url);
      store.commit("permits", {
        ...this.permitsData,
      });
    },
    async showPreviousPermits(url) {
      this.permitsData = await this.previousPaginate(url);
      store.commit("permits", {
        ...this.permitsData,
      });
    },
    getMomentDate(value) {
      return moment(value).locale("FR").format("DD/MM/YYYY");
    },
    scrollTable() {
      const tableContainer = this.$refs.tableResponsive;
      if (tableContainer) {
        const scrollPercentage = this.scrollValue / this.maxScroll;
        const maxScrollAmount =
          tableContainer.scrollWidth - tableContainer.clientWidth;
        const scrollAmount = maxScrollAmount * scrollPercentage;
        tableContainer.scrollLeft = scrollAmount;
      }
    },
    onTableScroll() {
      const tableContainer = this.$refs.tableResponsive;
      if (tableContainer) {
        const maxScrollAmount =
          tableContainer.scrollWidth - tableContainer.clientWidth;
        if (maxScrollAmount > 0) {
          const scrollPercentage = tableContainer.scrollLeft / maxScrollAmount;
          this.scrollValue = scrollPercentage * this.maxScroll;
        }
      }
    },
  },
  // eslint-disable-next-line vue/order-in-components
  mounted() {
    const query = { ...this.$route.query };
    if (query.page && query.size) {
      this.filter.page = query.page;
      this.filter.size = query.size;
    }
    this.getAllPermits();
  },
};
</script>
